.plans {
  align-items: start;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}

.plan {
  border: 1px solid #eee;
  margin: 0;
  text-align: center;  
}

.plan:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, .15);
  transition: .3s;
}

.plan dt,
.plan dd {
  border-bottom: 1px solid #eee;
  color: #000;
  font-size: 80%;
  margin: 0;
  padding: 20px;
}

.plan .name {
  background: #4e6488;
  color: #fff;
  font-size: 160%;
}

.plan.recommended .name {
  background: #ff8400;
}

.plan .price,
.plan .choice {
  background: #eee;
  color: #000;
  font-size: 125%;
}

.plan .choice {
  padding: 0;
}

.plan .choice .radio {
  justify-content: center;
  padding: 20px;
}

@media only screen and (max-width: 767px) {
  .plans {
    grid-template-columns: auto;
  }
}
